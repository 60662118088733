export class MonthSelectorComponent {
  constructor(element) {
    this.el = element;
    if (!this.el || !this.el.length) {
      throw new MonthSelectorComponentError("Element is null or not found");
    }

    this.tabList = this.el.find("ul");
    if (!this.tabList) {
      throw new MonthSelectorComponentError("The provided element does not have an <ul>");
    }
    this.tabTemplate = $(this.el.find("template.month-selector-tab-template")[0].content.children);

    if (!this.el.attr("data-first")) {
      throw new MonthSelectorComponentError("Element does not have attribute 'data-first'");
    }
    this.firstMonth = new Month(...this.el.attr("data-first").split("-"));

    if (!this.el.attr("data-last")) {
      throw new MonthSelectorComponentError("Element does not have attribute 'data-last'");
    }
    this.lastMonth = new Month(...this.el.attr("data-last").split("-"));

    if (this.firstMonth.after(this.lastMonth)) {
      throw new MonthSelectorComponentError("data-first is after data-last");
    }

    if (!this.el.attr("data-selected")) {
      throw new MonthSelectorComponentError("Element does not have attribute 'data-selected'");
    }
    this.selectedMonth = new Month(...this.el.attr("data-selected").split("-"));

    this.prevControl = this.el.find("li.month-selector-previous");
    this.nextControl = this.el.find("li.month-selector-next");

    this.prevControl.detach();
    this.nextControl.detach();

    this.prevControl.appendTo(this.tabList);
    let i = 0;
    for (let currentDate = this.firstMonth; currentDate.compareTo(this.lastMonth) < 1; currentDate = currentDate.getNextMonth(), i++) {
      if (currentDate.equals(this.selectedMonth)) {
        this.activeTabIndex = i;
      }
      this.addMonth(currentDate);
    }
    if (this.activeTabIndex === undefined) {
      throw new MonthSelectorComponentError("The selected month is not between the first and last months")
    }
    this.nextControl.appendTo(this.tabList);
    this.allTabs = this.tabList.find("li.month-selector-month");
    this.updateDisplay();
    let t = this;
    this.prevControl.on("click", function() { if (!t.prevControl.hasClass("disabled")) { t.move(false); } ; return false; });
    this.nextControl.on("click", function() { if (!t.nextControl.hasClass("disabled")) { t.move(true); } ; return false; });
    this.resizeTimeout = null;
    if (window.ResizeObserver) {
      new ResizeObserver(this.throttledUpdateDisplay).observe(t.el[0]);
    } else {
      $(window).on("resize", function() { t.throttledUpdateDisplay(); });
    }
  }

  throttledUpdateDisplay = () => {
    clearTimeout(this.resizeTimeout);
    this.resizeTimeout = setTimeout(() => { this.updateDisplay(); }, 50);
  }

  move(isAfter) {
    let i = (isAfter ? this.activeTabs[this.activeTabs.length - 1] : this.activeTabs[0]);
    if (i == this.activeTabIndex) {
      i += (isAfter ? 1 : -1);
    }
    // clamp to the number of tabs
    this.activeTabIndex = Math.max(0, Math.min(i, this.allTabs.length - 1));
    this.updateDisplay();
  }

  addMonth(month) {
    let newMonthTab = $(this.tabTemplate[0].cloneNode(true));
    newMonthTab.addClass("month-selector-month");
    if (month.equals(this.selectedMonth)) {
      newMonthTab.addClass("active");
    }
    if (window.PontoClosedMonths) {
      let isClosed = window.PontoClosedMonths.some((closedMonth) => closedMonth.equals(month));
      newMonthTab.addClass(isClosed ? "month-closed" : "month-open");
    }
    let link = newMonthTab.find("a");
    link.attr("href", link.attr("href").replace("_year_", month.getYear()).replace("_month_", month.getMonth()));
    newMonthTab.find(".month").text(month.getShortMonthName());
    newMonthTab.find(".year").text(month.getYear());
    this.tabList.append(newMonthTab);
  }

  updateDisplay() {
    let containerWidth = this.tabList.width();
    let activeTabWidth = this.allTabs.eq(this.activeTabIndex).width();
    let avaliableWidth = containerWidth - this.prevControl.width() - this.nextControl.width() - activeTabWidth;
    let usedWidth = 0;
    this.activeTabs = [this.activeTabIndex];
    for (;;) {
      let oldUsedWidth = usedWidth;
      usedWidth = this.#addTab(usedWidth, avaliableWidth, false);
      usedWidth = this.#addTab(usedWidth, avaliableWidth, true)
      if (oldUsedWidth == usedWidth) {
        break;
      }
    }
    this.allTabs.filter((idx) => !this.activeTabs.includes(idx)).hide()
    this.allTabs.filter((idx) => this.activeTabs.includes(idx)).show();
    this.prevControl.toggleClass("disabled", this.activeTabs[0] == 0);
    this.nextControl.toggleClass("disabled", this.activeTabs[this.activeTabs.length - 1] == this.allTabs.length - 1);
  }

  #addTab(usedWidth, avaliableWidth, isAfter) {
    let tabLastIndex = this.allTabs.length - 1;
    let i = isAfter ? this.activeTabs[this.activeTabs.length - 1] + 1 : this.activeTabs[0] - 1;
    let tw = this.allTabs.eq(i).width();
    if (((!isAfter && i >= 0) || (isAfter && i <= tabLastIndex)) && usedWidth + tw <= avaliableWidth) {
      usedWidth += tw;
      if (isAfter) {
        this.activeTabs.push(i);
      } else {
        this.activeTabs.unshift(i);
      }
    }
    return usedWidth;
  }
}

export class MonthSelectorComponentError extends Error {
  constructor(message) {
    super(message);
    this.name = "MonthSelectorComponentError";
  }
}

export class Month {
  #year;
  #month;
  #shortMonthName;

  constructor(year, month) {
    if (isNaN(parseInt(year)) || year < 2000) {
      throw new MonthSelectorComponentError(`Invalid year: ${year}`);
    }
    if (isNaN(parseInt(month)) || month < 0 || month > 12) {
      throw new MonthSelectorComponentError(`Invalid month: ${month}`);
    }
    this.#year = parseInt(year);
    this.#month = parseInt(month);
    this.#shortMonthName = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"][this.#month - 1]
  }

  getYear() { return this.#year };
  getMonth() { return this.#month };
  getShortMonthName() { return this.#shortMonthName };

  getNextMonth() {
    let m = this.getMonth() + 1;
    let y = this.getYear();
    if (m > 12) {
      m = 1;
      y++;
    }
    return new Month(y, m);
  }

  getPreviousMonth() {
    let m = this.getMonth() - 1;
    let y = this.getYear();
    if (m < 1) {
      m = 12;
      y--;
    }
    return new Month(y, m);
  }

  compareTo(other) {
    let otherName = other === null ? "null" : other === undefined ? "undefined" : other.constructor.name;
    if (otherName != this.constructor.name) {
      throw new MonthSelectorComponentError(`Invalid comparison between Month and ${otherName}`);
    }
    if (this.getYear() < other.getYear() || (this.getYear() == other.getYear() && this.getMonth() < other.getMonth())) {
      return -1;
    }
    if (this.getYear() > other.getYear() || (this.getYear() == other.getYear() && this.getMonth() > other.getMonth())) {
      return 1;
    }
    return 0;
  }

  equals(other) { return this.compareTo(other) == 0 };
  before(other) { return this.compareTo(other) == -1 };
  after(other) { return this.compareTo(other) == 1 };
}
