export default class AdjustmentRequestModalComponent {
  constructor(el) {
    el.on("click", this.handleClick);

    this.el = el;
    this.loading = true;
    this.modal = null;
    this.timeRecords = [];
    this.isAllowance = false;

    this.timeAdjustmentContent = `
      <a class="btn btn-outline-primary btn-block" id="time-record-before">Adicionar antes</a>
      <div id="time-records" class="mt-2"></div>
      <a class="btn btn-outline-primary btn-block" id="time-record-after">Adicionar depois</a>
      <hr />
      <textarea placeholder="Motivo da solicitação" class="form-control" rows="5" name="adjustment_request[justification]"></textarea>
      <hr />
      <div class="form-group">
        <div class="form-label">Anexo</div>
        <div class="custom-file">
          <input type="file" class="custom-file-input" name="adjustment_request[proof]">
          <label class="custom-file-label" for="validatedCustomFile">Escolher arquivo...</label>
        </div>
      </div>
    `;

    this.allowanceContent = `
      <div class="row form-group">
        <div class="col d-inline-flex align-items-center"">
          Abonar o dia todo?
        </div>
        <div class="col col-auto">
          <div id="all-day-allowance" class="btn-group" role="group">
            <button type="button" class="btn btn-outline-primary col-6" data-option="no">
              Não
            </button>
            <button type="button" class="btn btn-primary col-6" data-option="yes">
              Sim
            </button>
          </div>
        </div>
      </div>
      <div class="d-none" id="allowance-duration">
        <div class="form-group">
          <label class="form-label">Quantidade de horas a ser abonada</label>
          <input type="text" name="adjustment_request[allowance_duration]" maxlength="5" value="08:00" class="form-control" />
        </div>
      </div>
      <hr />
      <div class="row form-group">
        <div class="col ">
          É um atestado médico?
        </div>
        <div class="col col-auto">
          <div id="medical-certificate" class="btn-group" role="group">
            <button type="button" class="btn btn-primary col-6" data-option="no">
              Não
            </button>
            <button type="button" class="btn btn-outline-primary col-6" data-option="yes">
              Sim
            </button>
          </div>
        </div>
      </div>
      <div class="d-none" id="medical-justification">
        <div class="form-group">
          <label class="form-label">Nome completo do médico</label>
          <input type="text" class="form-control" name="adjustment_request[medic_name]" />
        </div>
        <div class="form-group">
          <label class="form-label">Qual o CRM do médico</label>
          <input type="text" class="form-control" name="adjustment_request[medic_identifier]" />
        </div>
        <div class="form-group">
          <label class="form-label">CID gerado no atendimento</label>
          <input type="text" class="form-control" name="adjustment_request[medical_code]" />
        </div>
        <div class="form-group">
          <label class="form-label">Qual local do atendimento?</label>
          <input type="text" class="form-control" name="adjustment_request[medical_place]" />
        </div>
      </div>
      <hr />
      <textarea class="form-control" rows="5" placeholder="Motivo da solicitação" name="adjustment_request[justification]"></textarea>
      <hr />
      <div class="form-group">
        <div class="form-label">Anexo</div>
        <div class="custom-file">
          <input type="file" class="custom-file-input" name="adjustment_request[proof]" required>
          <label class="custom-file-label" for="validatedCustomFile">Escolher arquivo...</label>
        </div>
      </div>
    `;
  }

  errorContainer = () => {
    return this.modal.find("#error-container");
  }

  buildTimeRecord = (record) => {
    const id = `time-record-${Math.random() * 512384}`;

    return `
      <div class="border-top pb-2 time-record-container" id="${id}">
        <div class="row">
          <div class="col">
            <label>Marcação #<span class="time-record-index"></span></label>
            <input type="time" name="adjustment_request[time_records_attributes][][marked_time]" value="${record ? record.time : ""}" class="form-control" />
          </div>
          <div class="col col-auto d-inline-flex align-items-end">
            <div class="btn-group time-record-duplicator-trigger" role="group">
              <button type="button" class="btn btn-danger col-6" data-option="remove">
                <i class="fa fa-minus"></i>
              </button>
              <button type="button" class="btn btn-primary col-6" data-option="add">
                <i class="fa fa-plus"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    `;
  }

  buildExistingTimeRecords = () => {
    this.modal.find("#time-records").html("");

    this.timeRecords.forEach((record, index) => {
      this.modal.find("#time-records").append(this.buildTimeRecord(record));
      this.updateTimeRecordIndex();
    });
  }

  timeAdjustments = () => {
    return this.modal.find(".time-record-container");
  }

  createModal = () => {
    const dateRe = /^(\d{4})-(\d{2})-(\d{2})$/;
    let date = this.el.data("when");
    this.modal = buildModal({
      header: {
        title: "Solicitar ajuste para " + date.replace(dateRe, '$3/$2/$1')
      },
      body: `
        <div id="error-container" class="d-none alert alert-danger">
        </div>
        <form>
          <input type="hidden" name="adjustment_request[user_id]" value="${this.el.data("who")}" />
          <input type="hidden" name="adjustment_request[adjustment_date]" value="${this.el.data("when")}" />
          <input type="hidden" name="adjustment_request[adjustment_type]" value="time_record" />
          <input type="hidden" name="adjustment_request[medical_certificate]" value="false" />
          <div class="row mb-3">
            <div id="kind-selector" class="btn-group col-md-6 offset-md-3" role="group">
              <button type="button" class="btn btn-primary col-6" data-kind="adjustment">
                Ajuste de horas
              </button>
              <button type="button" class="btn btn-outline-primary col-6" data-kind="allowance">
                Abono
              </button>
            </div>
          </div>
          <hr />
          <div id="adjustment-type">
            <div class="text-center">Aguarde...</div>
          </div>
        </form>
      `,
      footer: `
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
        <button type="button" class="btn btn-primary">Enviar solicitação</button>
      `
    });

    this.modal.modal("show");
  }

  setKind = (isAllowance) => {
    this.modal
    .find("#kind-selector .btn")
      .removeClass("btn-primary")
      .removeClass("btn-outline-primary")
      .end()
    .find("#kind-selector [data-kind='adjustment']")
      .addClass(isAllowance ? "btn-outline-primary" : "btn-primary")
      .end()
    .find("#kind-selector [data-kind='allowance']")
      .addClass(isAllowance ? "btn-primary" : "btn-outline-primary")
      .end()
    .find("[name*=adjustment_type]").val(isAllowance ? "allowance" : "time_record")
      .end()
    .find("#adjustment-type")
      .html(isAllowance ? this.allowanceContent : this.timeAdjustmentContent)
      .end();

    if (!isAllowance) {
      this.buildExistingTimeRecords();
    } else {
      new Cleave("[name*=allowance_duration]", {
        time: true,
        timePattern: ["h", "m"]
      });
    }

    this.isAllowance = isAllowance;
  }

  handleClick = async () => {
    this.who = this.el.data("who");
    this.when = this.el.data("when");
    let kind = this.el.data("kind");
    if (kind != "adjustment" && kind != "allowance") {
      throw("AdjustmentRequestModalComponent element missing or unrecognized data-kind");
    }

    if (!this.who || !this.when) {
      throw("AdjustmentRequestModalComponent element missing data-who or data-when");
    }

    this.createModal();

    this.timeRecords = null;
    let response = null;
    try {
      response = await fetch(`/users/${this.who}/time_records_from_date/${this.when}.json`);
      if (response.status == 401) {
        location.assign("/");
        return;
      }
      if (!response.ok) {
        alert(`Não foi possível carregar as informações.\n\nHTTP ${response.status} ${response.statusText}`);
        this.loading = false;
        this.modal.modal("hide");
        return;
      }
      const text = await response.text();
      this.timeRecords = JSON.parse(text);
    } catch (e) {
      this.loading = false;
      Sentry.captureException(e);
      alert("Não foi possível carregar as informações.");
      this.modal.modal("hide");
      return;
    }

    if (this.timeRecords === null) {
      this.loading = false;
      this.modal.modal("hide");
      return;
    }

    this.loading = false;

    this.modal.on("submit", "form", this.handleSubmit);
    this.modal.on("click", "#kind-selector .btn", (e) => {
      if (this.loading) {
        return;
      }

      const isAllowance = $(e.currentTarget).data("kind") === "allowance";

      this.setKind(isAllowance);
    });
    this.modal.on("click", "#medical-certificate .btn", (e) => {
      this.modal.find("#medical-certificate .btn")
        .removeClass("btn-primary")
        .removeClass("btn-outline-primary");

      const yes = $(e.currentTarget).data("option") === "yes";

      this.modal
        .find("#medical-certificate [data-option=yes]")
          .addClass(yes ? "btn-primary" : "btn-outline-primary")
          .end()
        .find("#medical-certificate [data-option=no]")
          .addClass(yes ? "btn-outline-primary" : "btn-primary")
          .end()
        .find("[name*=medical_certificate]")
          .val(yes)
          .end()
        .find("#medical-justification")
          .removeClass("d-block")
          .removeClass("d-none")
          .addClass(yes ? "d-block" : "d-none")
          .find("input")
            .val("");
    });
    this.modal.on("click", "#all-day-allowance .btn", (e) => {
      this.modal.find("#all-day-allowance .btn")
        .removeClass("btn-primary")
        .removeClass("btn-outline-primary");
      const yes = $(e.currentTarget).data("option") === "yes";

      this.modal
        .find("#all-day-allowance [data-option=yes]")
          .addClass(yes ? "btn-primary" : "btn-outline-primary")
          .end()
        .find("#all-day-allowance [data-option=no]")
          .addClass(yes ? "btn-outline-primary" : "btn-primary")
          .end()
        .find("#allowance-duration")
          [yes ? "addClass" : "removeClass"]("d-none");
    });
    this.modal.on("click", ".modal-footer .btn-primary", this.handleSubmit);
    this.modal.on("click", ".time-record-duplicator-trigger .btn", (e) => {
      if (this.timeAdjustments().length >= RECORDS_PER_DAY) {
        return;
      }

      const parent = $(e.currentTarget).closest(".time-record-container");
      const add = $(e.currentTarget).data("option") === "add";

      if (add) {
        $(this.buildTimeRecord()).insertAfter(parent);
        this.updateTimeRecordIndex();
        return;
      }

      parent.remove();
    });
    this.modal.on("click", "#time-record-before", () => {
      if (this.timeAdjustments().length >= RECORDS_PER_DAY) {
        return;
      }

      this.modal.find("#time-records").prepend(this.buildTimeRecord());
      this.updateTimeRecordIndex();
      this.modal.find("#time-records input[type='time']").first().focus();
    });
    this.modal.on("click", "#time-record-after", () => {
      if (this.timeAdjustments().length >= RECORDS_PER_DAY) {
        return;
      }

      this.modal.find("#time-records").append(this.buildTimeRecord());
      this.updateTimeRecordIndex();
      this.modal.find("#time-records input[type='time']").last().focus();
    });

    this.setKind(kind == "allowance");
  }

  updateTimeRecordIndex = () => {
    this.modal.find(".time-record-index").each((index, el) => {
      $(el).html(index + 1);
    });
  }

  handleSubmit = () => {
    if (this.loading) {
      return false;
    }

    this.submitForm();

    return false;
  }

  submitForm = async () => {
    this.loading = true;
    this.errorContainer()
      .html("")
      .addClass("d-none");

    let response = null;
    try {
      response = await fetch("/adjustment_requests", {
        method: "post",
        body: new FormData(this.modal.find("form").get(0)),
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").getAttribute("content")
        },
        credentials: "same-origin"
      });
      const text = await response.text();
      if (response.ok) {
        alert("Solicitação enviada com sucesso.");
        this.loading = false;
        location.reload();
        return;
      } else {
        const json = JSON.parse(text);
        alert(`Não foi possível salvar a solicitação de ajuste.\n\n• ${json.join("\n• ")}`);
      }
    } catch (e) {
      Sentry.captureException(e);
      alert(`Erro interno do servidor.\n\n${response ? `HTTP ${response.status} ${response.statusText}` : ""}`);
    }
    this.loading = false;
  }
}
