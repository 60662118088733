export default class WorkQuotaDuplicatorComponent {
  constructor(el) {
    this.el = el;

    el
      .on("duplicator:duplicated", () => {
        el.find(".duplicator-item select").trigger("change");
      })
      .on("change", "select", (e) => {
        this.updateYears($(e.target).closest(".duplicator-item"), e.target.value);
      });
  }

  updateYears(item, year) {
    item.find("[name*=year]").val(year);
  }
}
