export default class DuplicatorComponent {
  constructor(el) {
    this.el = el;
    this.templateElement = this.el.find('.duplicator-template');

    if (!this.templateElement.length) {
      throw new Error("Duplicator template element isn't defined");
    }

    this.template = $(this.templateElement.get(0).outerHTML);
    this.el.get(0).template = this.template;
    this.templateElement.remove();

    this.el
      .on('click', '.duplicator-remove-item', ($event) => this.remove($event))
      .on('click', '.duplicator-trigger', () => this.duplicate());
  }
  duplicate() {
    let clone = this.template.clone().css({
      display: 'inherit'
    }).removeClass('duplicator-template').addClass('duplicator-item');

    ["id", "for"].forEach((attr) => {
      clone.find(`[${attr}]`).each((idx, elem) => {
        let t = $(elem);
        let id = t.attr(attr);
        let m = id.match(/\d+$/);
        let idPrefix = id + "-";
        let num = 0;
        if (m && m[0]) {
          idPrefix = id.replace(/\d+$/, "");
          num = parseInt(m[0]);
        }
        while(true) {
          num++;
          let newId = idPrefix + num;
          if (!$(`#${newId}`).length) {
            t.attr(attr, newId);
            break;
          }
        }
      });
    });

    this.el
      .find('.duplicator-content')
      .append(clone)
      .end()
      .trigger('duplicator:duplicated');
  }
  remove($event) {
    let item = $($event.target).closest('.duplicator-item');
    let removeFlag = item.find('[name*=_destroy]');
    let hoursField = item.find('[name*=hours]');

    if (removeFlag.length) {
      item.addClass('d-none');
      removeFlag.val('1');
    } else if (hoursField.length) {
      item.addClass('d-none');
      hoursField.val('');
    } else {
      item.remove();
    }

    this.el.trigger('duplicator:removed', { item: item });
  }
}
