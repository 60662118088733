import { Loader } from "@googlemaps/js-api-loader";

const loader = new Loader({
  apiKey: "AIzaSyDfrynFxaApft8FvHesz0f1ioNGSktsOgY",
  id: "geocodingComponentMapsLib",
  language: "pt-BR",
  region: "br"
});

export default class GeocodingComponent {
  constructor(element) {
    this.element = element;
    this.mapElement = element.find(".geocoding-map");
    this.latitudeField = element.find(".geocoding-latitude");
    this.longitudeField = element.find(".geocoding-longitude");
    this.deltaField = element.find(".geocoding-delta");
    this.addressField = element.find(".geocoding-address");
    this.searchAddressButton = element.find(".geocoding-address-search");
    this.searchResultsElement = element.find(".geocoding-search-results");

    this.fieldStep = Number(element.attr("data-location-step"));
    this.inverseStep = Math.round(1 / this.fieldStep);

    this.defaultCoords = [-23.630033929020517, -46.70733693704526];
    this.defaultDelta = 500;
    this.position = { lat: Number(this.latitudeField.val()), lng: Number(this.longitudeField.val()) }
    if (!this.position.lat || !this.position.lng) {
      this.position = { lat: this.defaultCoords[0], lng: this.defaultCoords[1] };
      this.latitudeField.val(this.position.lat);
      this.longitudeField.val(this.position.lng);
    }
    this.delta = Number(this.deltaField.val());
    if (!this.delta) {
      this.delta = this.defaultDelta;
      this.deltaField.val(this.delta);
    }

    this.position.lat = this.roundCoordinate(this.position.lat);
    this.position.lng = this.roundCoordinate(this.position.lng);

    $(window).on("resize", this.handleResize);
    this.latitudeField.attr("step", this.fieldStep).on("change", this.updatePositionFromFields);
    this.longitudeField.attr("step", this.fieldStep).on("change", this.updatePositionFromFields);
    this.deltaField.on("change", this.updatePositionFromFields);
    this.addressField.on("keypress", this.handleAddressKey);
    this.searchAddressButton.on("click", this.handleAddressSearch);

    $(this.handleLoad);
  }

  getPositionBounds = () => {
    const { lat, lng } = this.position;
    const latDelta = this.delta / 2 / 111111.1;
    const lngDelta = this.delta / 2 / 111111.1 * Math.cos(lat * Math.PI / 180.0);
    const r = {north: lat - latDelta, south: lat + latDelta, west: lng - lngDelta, east: lng + lngDelta};
    console.log({lat, latDelta, lng, lngDelta, ...r});
    return r;
  }

  roundCoordinate = (coord) => {
    return Math.round(coord / this.fieldStep) / this.inverseStep;
  }

  handleLoad = async () => {
    this.handleResize();

    const { Map, Rectangle } = await loader.importLibrary("maps");
    const { AdvancedMarkerElement } = await loader.importLibrary("marker");
    const { Geocoder } = await loader.importLibrary("geocoding");

    this.map = new Map(this.mapElement[0], {
      center: this.position,
      zoom: 16,
      mapId: "cost_center_map"
    });

    this.markerArea = new Rectangle({
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
      map: this.map,
      bounds: this.getPositionBounds(),
      zIndex: 1
    });

    this.marker = new AdvancedMarkerElement({
      map: this.map,
      position: this.position,
      zIndex: 2,
      gmpDraggable: true
    });

    this.geocoder = new Geocoder();

    this.marker.addListener("drag", this.updatePositionFromMarker);

    setTimeout(() => {
      this.updatePositionFromMarker();
    }, 500);
  }

  updatePositionFromMarker = () => {
    const { lat, lng } = this.marker.position;
    this.position = { lat, lng };
    this.markerArea.setBounds(this.getPositionBounds());
    this.latitudeField.val(this.roundCoordinate(lat));
    this.longitudeField.val(this.roundCoordinate(lng));
  }

  updatePositionFromFields = () => {
    const lat = Number(this.latitudeField.val());
    const lng = Number(this.longitudeField.val());
    this.delta = Number(this.deltaField.val());
    this.position = { lat, lng };
    this.map.setCenter(this.position);
    this.marker.position = this.position;
    this.markerArea.setBounds(this.getPositionBounds());
  }

  handleAddressKey = (e) => {
    if (e.which == 13) { // pressed Enter on address search: don't submit the form
      e.preventDefault();
      this.handleAddressSearch();
    }
  }

  handleAddressSearch = () => {
    const address = this.addressField.val();
    this.searchResultsElement.html(`<div class="spinner-border" role="status"></div>`);
    this.addressField.prop("disabled", true);
    this.searchAddressButton.prop("disabled", true);
    this.geocoder.geocode({address, region: "br"}, this.handleGeocode);
  }

  handleGeocode = (results, status) => {
    this.addressField.prop("disabled", false);
    this.searchAddressButton.prop("disabled", false);
    this.searchResultsElement.html("");
    if (status == "ZERO_RESULTS") {
      this.searchResultsElement.text("Nenhum resultado encontrado.");
      return;
    }
    if (status != "OK") {
      this.searchResultsElement.html("<div class='text-danger'>Erro ao buscar o endereço.</div>");
      return;
    }
    const list = $("<ul></ul>");
    results.forEach(result => {
      const li = $("<li></li>");
      const link = $("<a href='#'></a>");
      link.text(result.formatted_address);
      link.on("click", (e) => {
        e.preventDefault();
        this.addressField.val(result.formatted_address);
        this.latitudeField.val(this.roundCoordinate(result.geometry.location.lat()));
        this.longitudeField.val(this.roundCoordinate(result.geometry.location.lng()));
        this.updatePositionFromFields();
      });
      li.append(link);
      list.append(li);
    });
    this.searchResultsElement.append("<p>Clique no resultado desejado:</p>");
    this.searchResultsElement.append(list);
  }

  handleResize = () => {
    this.mapElement.css({height: `${this.mapElement.width() / 1.5}px`});
  }
}
