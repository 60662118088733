export default class AdjustmentRequestsDetailModalComponent {
  constructor(el) {
    el.on("click", this.handleClick);

    this.el = el;
    this.loading = true;
    this.modal = null;
  }

  createModal = () => {
    this.modal = buildModal({
      header: {
        title: "Ajustes solicitados"
      },
      body: "Aguarde...",
      footer: `
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fechar</button>
      `
    });
  }

  loadAdjustmentData = async () => {
    let adjustments = null;
    let response = await fetch(`/users/${this.el.data('who')}/adjustment_requests_from_date/${this.el.data('when')}.json`);
    try {
      if (response.status == 401) {
        location.assign("/");
        return;
      }
      if (!response.ok) {
        alert(`Não foi possível carregar as informações.\n\nHTTP ${response.status} ${response.statusText}`);
        this.loading = false;
        this.modal.modal("hide");
        return;
      }
      const text = await response.text();
      adjustments = JSON.parse(text);
    } catch (e) {
      this.modal.modal("hide");
      return;
    }

    let body = `
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Ajuste</th>
            <th>Status</th>
            <th>Administrador</th>
            <th width="1"></th>
          </tr>
        </thead>
        <tbody>
    `;

    adjustments.forEach((adjustment, index) => {
      const detailId = `adjustment-request-${adjustment.id}-${index}`;
      let detail = `<td colspan="999">`

      if (adjustment.adjustment_type === "allowance") {
        const hours = Math.floor(adjustment.allowance_duration / 3600);
        const minutes = Math.floor(adjustment.allowance_duration % 3600 / 60);

        detail += `
          <div class="form-group">
            <label class="form-label">Total de horas abonadas:</label>
            <div class="form-control">
              ${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}
            </div>
          </div>
        `;

        detail += `
          <div class="form-group">
            <label class="form-label">Atestado médico:</label>
            <div class="form-control">
              ${adjustment.medical_certificate ? "<span class='fa fa-check'></span> Sim" : "<span class='fa fa-times'></span> Não"}
            </div>
          </div>
        `;

        if (adjustment.medical_certificate) {
          detail += `
            <div class="form-group">
              <label class="form-label">Nome completo do médico:</label>
              <div class="form-control">
                ${adjustment.medic_name}
              </div>
            </div>
            <div class="form-group">
              <label class="form-label">CRM do médico:</label>
              <div class="form-control">
                ${adjustment.medic_identifier}
              </div>
            </div>
            <div class="form-group">
              <label class="form-label">CID gerado no atendimento:</label>
              <div class="form-control">
                ${adjustment.medical_code}
              </div>
            </div>
            <div class="form-group">
              <label class="form-label">Local do atendimento:</label>
              <div class="form-control">
                ${adjustment.medical_place}
              </div>
            </div>
          `;
        }
      } else {
        if (!adjustment.time_records.length) {
          detail += `
            <div class="alert alert-info">
              O Ajuste removeu as marcações existentes do dia
            </div>
          `;
        } else {
          adjustment.time_records.forEach((record, recordIndex) => {
            detail += `
              <div class="form-group">
                <label class="form-label">${recordIndex + 1}a Marcação:</label>
                <div class="form-control">
                  ${record.time}
                </div>
              </div>
            `;
          });

        }
      }

      detail += "<hr />";

      if (adjustment.proof_url) {
        detail += `
          <div class="form-group">
            <label class="form-label">Comprovante:</label>
            <div class="form-control">
              <a href="${adjustment.proof_url}" target="_new">Visualizar comprovante</a>
            </div>
          </div>
        `;
      }

      detail += `
        <div class="form-group">
          <label class="form-label">Motivo da solicitação:</label>
          <div class="form-control">
            ${adjustment.justification}
          </div>
        </div>
      `;

      if (adjustment.status === "rejected") {
        detail += `
          <hr />
          <div class="form-group">
            <label class="form-label">Motivo da rejeição:</label>
            <div class="form-control">
              ${adjustment.reject_reason}
            </div>
          </div>
        `;
      }

      detail += "</td>";
      body += `
        <tr data-adjustment-id='${adjustment.id}'>
          <td>${adjustment.adjustment_type_text}</td>
          <td>${adjustment.status_text}</td>
          <td>${adjustment.approver.name || "--"}</td>
          <td><button type="button" class="btn btn-primary" data-toggle="collapse" data-target="#${detailId}">Detalhes</button></td>
        </tr>
        <tr id="${detailId}" class="collapse">
          ${detail}
        </tr>
      `;
    });

    if (!adjustments.length) {
      body += `
        <tr>
          <td colspan="999">Nenhum ajuste foi solicitado nesse dia</td>
        </tr>
      `;
    }

    body += "</tbody></table>";

    this.modal.find(".modal-body").html(body);
  }

  handleClick = async () => {
    this.createModal();
    this.modal.modal('show');
    await this.loadAdjustmentData();
  }
}
