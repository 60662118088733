window.InitializeComponents = (function() {
  function InitializeComponents(i, el) {
    var className, classNames, error, instance, instanceName, j, len;
    el = $(el);
    if (!(el.data('component') === void 0 || el.hasClass('initialized'))) {
      el.addClass('initialized');
      classNames = el.data('component').split(' ').map(function(c) {
        return c + "Component";
      });
      for (j = 0, len = classNames.length; j < len; j++) {
        className = classNames[j];
        instanceName = className.charAt(0).toLowerCase() + className.slice(1);
        if (!el.data(instanceName)) {
          if (!window[className]) {
            console.warn("Component requested by", el[0]);
            throw new Error(`undefined component ${className}`);
          }
          instance = new window[className](el);
          el.data(instanceName, instance);
        }
      }
    }
  }

  return InitializeComponents;
})();
