// Entry point for the build script in your package.json
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: (RAILS_ENV == "production" || RAILS_ENV == "staging") ? "https://ca6f49e57dee4ce7ab0bb7f44e21ff3f@o108907.ingest.sentry.io/1425134" : null,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: RAILS_ENV == "staging" ? 1.0 : 0.2,
  environment: RAILS_ENV
});
window.Sentry = Sentry;

import Rails from "@rails/ujs"
Rails.start()

import "@rails/activestorage";

import "Channels/index";

import Popper from "popper.js";

import jQuery from "jquery";
window.$ = jQuery;
window.jQuery = jQuery;

import "jquery-ui";

import "bootstrap";

if (RAILS_ENV == "test") {
  $.fx.off = true;
  $.support.transition = false;
}

import "Vendor/cleave";

import AdjustmentRequestModalComponent from "Components/adjustment_request_modal";
window.AdjustmentRequestModalComponent = AdjustmentRequestModalComponent;

import AdjustmentRequestsDetailModalComponent from "Components/adjustment_requests_detail_modal";
window.AdjustmentRequestsDetailModalComponent = AdjustmentRequestsDetailModalComponent;

import { Month, MonthSelectorComponent } from "Components/month_selector"
window.Month = Month;
window.MonthSelectorComponent = MonthSelectorComponent;

import ServerClockComponent from "Components/server_clock";
window.ServerClockComponent = ServerClockComponent;

import DuplicatorComponent from "Components/duplicator";
window.DuplicatorComponent = DuplicatorComponent;

import WorkQuotaDuplicatorComponent from "Components/work_quota_duplicator";
window.WorkQuotaDuplicatorComponent = WorkQuotaDuplicatorComponent;

import GeocodingComponent from "Components/geocoding";
window.GeocodingComponent = GeocodingComponent;

import "Helpers/initialize_components";
import "Helpers/modal_builder";

import("App/home");

var applyComponents = function () {
  if (document == null /* Jest */ || document.readyState == "interactive" || document.readyState == "complete") {
    $("[data-component]:not(.initialized)").each(InitializeComponents);
    $("[data-toggle=\"tooltip\"]:not(.with-tooltip)").tooltip().addClass("with-tooltip");
  }
  if (document) {
    $(document).on("change", ".custom-file-input", function() {
      var fileName = $(this).val().split("\\").pop();
      $(this).siblings(".custom-file-label").addClass("selected").html(fileName);
    });
  }
};

var componentObserver = new MutationObserver(function () {
  applyComponents();
});

componentObserver.observe(document, { childList: true, subtree: true });

$(() => {
  $("#menu-toggle").on("click", (e) => {
    e.preventDefault();
    $("#wrapper").toggleClass("toggled");
  });

  applyComponents();
  $("#welcome-message-modal").modal();
});
