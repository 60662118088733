window.buildModal = (props) => {
  const modalProperties = $.extend(true, {}, props || {}, {
    header: {
      close: true,
    },
    size: "lg",
  });

  const closeButton = `
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  `;

  const header = `
    <div class="modal-header">
      <h5 class="modal-title">%title</h5>
      %closeButton
    </div>
  `;

  const footer = `<div class="modal-footer"></div>`;

  let template = `
    <div class="modal" tabindex="-1">
      <div class="modal-dialog modal-${modalProperties.size}">
        <div class="modal-content">
          %header
          <div class="modal-body">
            %body
          </div>
          %footer
        </div>
      </div>
    </div>
  `;

  const buildHeader = () => {
    if (modalProperties.header) {
      const builtHeader = 
        header
          .replace("%title", modalProperties.header.title)
          .replace("%closeButton", modalProperties.header.close ? closeButton : "");

      return builtHeader;
    }

    return "";
  }

  const buildFooter = () => {
    if (modalProperties.footer) {
      const builtFooter = $(footer);

      return builtFooter.html(modalProperties.footer).get(0).outerHTML;
    }

    return "";
  }

  template = template.replace("%header", buildHeader());
  template = template.replace("%footer", buildFooter());
  template = template.replace("%body", modalProperties.body ? modalProperties.body : "");

  const modal = $(template);

  modal.on("hidden.bs.modal", () => {
    modal.remove();
  });

  $(document.body).append(modal);

  return modal;
}
