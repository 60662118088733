$(() => {
  const modal = $("#registerTimeModal");
  const loading = modal.find(".alert-warning");
  const success = modal.find(".alert-success");
  const trigger = modal.find(".btn-primary");
  const locationStatus = modal.find(".location-status");

  const locationRequired = modal.attr("data-location-required") == "1";

  let currentLocation = null;

  const postData = async () => {
    modal.find(".btn-secondary").remove();

    const formData = new FormData();

    if (currentLocation) {
      const currentDate = new Date();
      if (currentDate - currentLocation.timestamp > 300000) {
        alert("Localização desatualizada.\n\nA página será atualizada. Tente novamente.");
        location.reload();
        return false;
      }
      formData.append("time_record[latitude]", currentLocation.coords.latitude);
      formData.append("time_record[longitude]", currentLocation.coords.longitude);
      formData.append("time_record[location_accuracy]", currentLocation.coords.accuracy);
    } else if (locationRequired) {
      alert("Não foi possível obter a sua localização.\n\nA página será atualizada. Tente novamente.");
      location.reload();
      return false;
    }

    formData.append("time_record[user_clock]", new Date());
    formData.append("time_record[user_agent]", navigator.userAgent);

    try {
      const response = await fetch("/time_records", {
        method: "post",
        body: formData,
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").getAttribute("content")
        },
        credentials: "same-origin"
      });
      const text = await response.text();
      const json = JSON.parse(text);
      if (!json.success) {
        alert(`O servidor indicou um erro:\n\n• ${json.messages.join("\n• ")}\n\nA página será atualizada. Verifique se a marcação foi efetuada antes de tentar novamente.`);
      }
    } catch (e) {
      Sentry.captureException(e);
      alert("Erro ao processar a resposta do servidor.\n\nA página será atualizada. Verifique se a marcação foi efetuada antes de tentar novamente.");
    }
    location.reload();
  };

  const initModal = () => {
    locationDate = null;
    currentLocation = null;
    trigger.prop("disabled", true);
    locationStatus.html("<div class='alert alert-info'><span class='spinner-border spinner-border-sm' role='status'></span> Carregando localização…</div>");
    if (!("geolocation" in navigator)) {
      updateLocation(false);
      return;
    }
    navigator.geolocation.getCurrentPosition((position) => { updateLocation(position); }, () => { updateLocation(false); }, {enableHighAccuracy: true});
  }

  const updateLocation = (position) => {
    if (position) {
      currentLocation = position;
      locationStatus.html("<div class='alert alert-success'>Localização carregada.</div>");
    } else {
      locationStatus.html(`<div class='alert alert-${locationRequired ? 'danger' : 'warning'}'>Localização não disponível.</div>`);
      if (locationRequired) {
        locationStatus.append("<p>Para registrar o ponto, utilize um dispositivo que possua GPS, certifique-se que o GPS está ativado, e autorize o acesso deste sistema à sua localização.</p>");
        return;
      }
    }
    trigger.prop("disabled", false);
  }

  modal.on("shown.bs.modal", initModal);
  modal.on("click", ".btn-primary", () => postData());

  const switchTooCloseMessage = () => {
    tooCloseMessage.hide();
    $("#home_mark_time_canvas").show();
  };

  const tooCloseMessage = $("#home_message_too_close");
  if (tooCloseMessage.length) {
    const availableInMs = parseInt(tooCloseMessage.attr("data-available-in") * 1000);
    if (availableInMs) {
      setTimeout(switchTooCloseMessage, availableInMs);
    }
  }
});
