export default class ServerClockComponent {
  constructor(element) {
    this.el = element;
    if (!this.el || !this.el.length) {
      throw "Element is null or not found";
    }
    this.clockDisplay = element.find(".clock_display");
    if (!this.clockDisplay || !this.clockDisplay.length) {
      throw "Element must contain a child with class 'clock_display'";
    }
    this.currentTime = null;
    this.timeOffset = null;
    this.dateReceived = null;

    this.clockPromise = this.updateClock();
  }

  updateClock = async () => {
    let response = null;
    try {
      response = await fetch("/clock.json");
      if (response.status == 401) {
        location.assign("/");
        return;
      }
      if (response.ok) {
        const text = await response.text();
        const data = JSON.parse(text)
        this.currentTime = data['time'];
        this.timeOffset = data['offset'];
        this.dateReceived = new Date().getTime();
        this.updateDisplay();
        return;
      } else {
        console.warn(`Could not retrieve the server time. HTTP ${response.status} ${response.statusText}`);
      }
    } catch (e) {
      Sentry.captureException(e);
    }
    this.currentTime = null;
    this.timeOffset = null;
    this.dateReceived = new Date().getTime();
    this.updateDisplay();
  }

  updateDisplay = () => {
    let currentDate = new Date().getTime();
    let elapsed = (currentDate - this.dateReceived) / 1000;
    if (this.currentTime) {
      let seconds = parseInt(this.currentTime + elapsed + this.timeOffset);
      let minutes = parseInt(seconds / 60);
      let hours = parseInt(minutes / 60);
      seconds = String(seconds % 60);
      minutes = String(minutes % 60);
      hours = String(hours % 24);
      if (seconds.length < 2) {
        seconds = `0${seconds}`;
      }
      if (minutes.length < 2) {
        minutes = `0${minutes}`;
      }
      if (hours.length < 2) {
        hours = `0${hours}`;
      }
      this.el.removeClass("alert-danger").addClass("alert-info");
      this.clockDisplay.html(`${hours}:${minutes}:${seconds}`);
    } else {
      this.el.removeClass("alert-info").addClass("alert-danger");
      this.clockDisplay.html("--:--:--");
    }
    if (elapsed > 60 /* seconds */ || (elapsed > 10 && this.currentTime == null)) {
      this.updateClock();
    } else {
      setTimeout(this.updateDisplay, 1000);
    }
  }
}
